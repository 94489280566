

/*MARK: jason's stuff */

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  justify-content: start;
}

.flex-between {
  justify-content: space-between;
}

.flex-around {
  justify-content: space-around;
}

.discover-page {
  /* position: absolute; */
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.filter-group {
  line-height: 1em;
  margin: 3px 0;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  color: var(--text-color);
  /* width: 100%; */
}

.tag-name {
  position: relative;
  cursor: pointer;
  text-align: right;
  /* width: 90%; */
  transition: color 400ms;
}

.remove-tag {
  position: relative;
  cursor: pointer;
  font-size: 1.5em;
  width: 5%;
  transition: color 400ms;
}

.reset-button {
  /* background-color: var(--primary-color-purple); */
  cursor: pointer;
  transition: color 600ms, background-color 600ms;
}

.added {
  color: chartreuse;
}

.removed {
  opacity: 0.2;
  text-decoration: line-through;
}

.results-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* flex-grow: 1; */
  /* width: 85%; */
  height: 100%;
}

.section-label {
  text-align: left;
  font-size: 1.75em;
  color: var(--muted-text-color);
}

.creator-preview {
  position: relative;
  width: 16%;
  min-width: 150px;
  /* padding: 1px; */
  background-color: #333;
  /* border-radius: 5px; */
  /* margin: 3px; */
  transition: filter ease 250ms;
}

.creator-preview:hover {
  filter: drop-shadow(0 0 0.5em #958f9d);
}
/* .creator-preview .preview {
  border-radius: 5px;
} */

.preview-caption {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.switch {
  position: relative;
  display: inline-block;
  width: 5em;
  height: 1.45em;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color-selected);
  -webkit-transition: .4s;
  transition: .4s;
}

/*--primary-color-purple: #00FF00;
  --background-charcoal: #2b2d2f;
  --text-color: #f7f2e7;
  --muted-text-color: #cecbcb;
  --post-background-color: #3a3b3b;
  --amplify-font-family: "Montserrat", sans-serif;
  --amplify-primary-color: #00FF00;
  --amplify-primary-contrast: #2b2d2f;
  --primary-color-selected: #904ce3;*/

.toggle-switch:before {
  position: absolute;
  content: "Any";
  text-align: center;
  color: var(--text-color);
  line-height: 1.25em;
  height: 1.25em;
  width: 3.5em;
  left: .25em;
  bottom: .1em;
  background-color: var(--primary-color-purple);
  -webkit-transition: .4s;
  transition: .4s, color 0.6s;
}

#switch-checkbox:focus + .toggle-switch {
  box-shadow: 0 0 1px var(--primary-color-selected);
}

#switch-checkbox:checked + .toggle-switch:before {
  content: "All";
  -webkit-transform: translateX(1em);
  -ms-transform: translateX(1em);
  transform: translateX(1em);
}

.toggle-switch.round {
  border-radius: 5px;
}

.toggle-switch.round:before {
  border-radius: 5px;
}

.mobile-filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  top: 70px;
  /*padding-top: 70px;*/
  background-color: var(--background-charcoal);
}

.mobile-filter-hamburger {
  padding-bottom: 10px;
  text-align: center;
}

.mobile-filter-container {
  position: absolute;
  width: 100%;
  top: 48px;
  display: flex;
  flex-direction: column;
  background-color: var(--background-charcoal);
  transition: transform 1s;
}

.mobile-filter-container.is-closed {
  transform: translateY(-200%);
}

.mobile-filter-container.is-opened {
  transform: translateY(10px);
}

.mobile-filter-options {
  display: flex;
  flex-direction: row;
  width: 30%;
  margin: 0 auto;
  justify-content: space-around;
}

.mobile-filter-hamburger {
  cursor: pointer;
}

.mobile-dropdown-menu {
  column-count: 6;
}
.mobile-dropdown-menu .filter-group {
  width: 100%;
  justify-content: center;
  margin: 0;
}

.mobile-filter-container .tag-name {
  width: 90%;
  text-align: right;
}

@media only screen and (max-width: 1024px) {
  .results-container {
    width: 100%;
  }
  .results-section {
    justify-content: center;
    width: 100%;
    margin: 0;
    z-index: -1;
  }
  .creator-results {
    align-items: center;
  }
  .creator-preview {
    width: 30%;
  }
  .discover-page {
    flex-direction: column;
    top: 95px;
  }
  .filter-group {
    line-height: 1.5em;
  }
  
}

@media only screen and (max-width: 600px) {
  .creator-preview {
    width: 45%;
  }
  .mobile-dropdown-menu {
    column-count: 3;
  }
}

@media only screen and (max-width: 350px) {
  .mobile-dropdown-menu {
    column-count: 2;
  }
}

@media (hover: hover) {
  .tag-name:hover {
    color: var(--primary-color-purple);
  }
  .tag-name.added:hover {
    color: var(--primary-color-selected);
  }

  .remove-tag:hover {
    color: red;
  }

  .reset-button:hover {
    color: var(--primary-color-purple);
    background-color: var(--primary-color-selected);
  }

  .hastt:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .creator-preview:hover .flyout {
    width: 95%;
    transition: width .6s;
    transition-delay: .5s;
    overflow: hidden;
    height: 95%;
  }
  .flyout p:hover {
    white-space: normal;
  }
}

.hastt .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  font-size: .75rem;
  text-align: center;
  white-space: nowrap;
  padding: .25em 1em;
  border-radius: 5px;
  pointer-events: none;

  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 75%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity .6s;
  transition-delay: 1s;
}

.hastt .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 85%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.flyout {
  height: 90%;
  position: absolute;
  width: 0;
  transition: width .1s;
  background-color: var(--background-charcoal);
  opacity: .8;
  overflow: hidden;
  white-space: nowrap;
}

.flyout > p {
  color: white;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}