#crop-container {
  all: unset;
  height: auto !important;
  overflow: visible;
  
}

/* .crop-container {
  position: relative;
} */

.crop-container > div {
  color: var(--background-charcoal) !important;
  
}

.reactEasyCrop_Container {
  /* all: unset; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  color: black !important;
  background: var(--background-charcoal) !important;
}

.reactEasyCrop_Image,
.reactEasyCrop_Video {
  max-width: 100%;
  /* width: 90%; */
  height: auto;
  margin: auto;
  position: absolute;
  
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: black !important;
  background-color: pink;
  will-change: transform; /* this improves performances and prevent painting issues on iOS Chrome */
}

.reactEasyCrop_CropArea {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  border: 1px solid blue;
  box-sizing: border-box;
  box-shadow: 0 0 0 9999em;
  color: rgba(0, 0, 0, 0.5);
  /* color: lime; */
  background-color: pink;
  overflow: hidden;
}

/* .reactEasyCrop_CropAreaRound {
  border-radius: 50%;
} */

.reactEasyCrop_CropAreaGrid::before {
  content: " ";
  box-sizing: border-box;
  position: absolute;
  border: 2px solid black;
  top: 0;
  bottom: 0;
  left: 33.33%;
  right: 33.33%;
  border-top: 0;
  border-bottom: 0;
  /* color: pink; */
}

.reactEasyCrop_CropAreaGrid::after {
  content: " ";
  box-sizing: border-box;
  position: absolute;
  border: 2px solid black;
  top: 33.33%;
  bottom: 33.33%;
  left: 0;
  right: 0;
  border-left: 0;
  border-right: 0;
  /* color: pink; */
}
