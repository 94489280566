

$primary: #00FF00;
$secondary: #007F34;

$body-bg: #14213D;
$body-color: white;

// $border-color: #FFFFFF;
$border-color: #3D3D3D;

$nav-pills-border-radius: 0%;

$nav-tabs-border-radius: 0%;

$border-radius-lg: 0%;

$nav-pills-link-active-bg: transparent;
$nav-pills-link-active-color: $body-bg;
// $nav-pills-link-active-weight: 600;
// $nav-link-font-weight: 600;
$link-decoration: none;
$nav-pills-border-radius: 0;

$link-hover-decoration: none;

.nav-link.active {
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.navbar, .navbar-dark, .bg-dark {
  background-color: $body-bg !important;
}

.btn-primary, .btn {
  background: $primary;
  color: $body-color !important;
}

.col {
  padding-left: 10px;
  padding-right: 10px;
}

// .btn-outline-primary {
//   &:hover {
//     background: $primary;
//   }
// }

// .btn-secondary {
//   color: #cecbcb !important;
//   font-size: 0.5rem !important;
// }

.container, .container-fluid {
  margin: 0px;
}

// .card-body {
//   margin: 0px;
//   padding: 0px;
// }

@import "~bootstrap/scss/bootstrap.scss";

@import "~bootstrap/scss/mixins";


