@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500&display=swap");
@import "./cropper.css";

/* #00FF00
#007F34
#3D3D3D
#000000 */

/* --primary-color: #bb86fc;
--primary-color-selected: #904ce3;
--background-mai: #000080;
--text-color: #f7f2e7;
--muted-text-color: #cecbcb; */

:root {
  --primary-color: #00FF00;
  --primary-color-selected: #007F34;
  --background-main: #f5f5f5;
  --text-color: #000000;
  --navy-blue: #14213D;
  --white-text-color: white;
  --muted-text-color: #3a3b3b;
  --post-background-color: #3a3b3b;
  --amplify-font-family: "Montserrat", sans-serif;
  --amplify-primary-color: #00FF00;
  --amplify-primary-contrast: #2b2d2f;
}

/* @font-face {
  font-family: "BeautySalon-Script", serif;
  src: url('../fonts/BeautySalon_Script/BeautySalon_Script-Regular.ttf') format("TrueType"),
      url('../fonts/BeautySalon_Script/BeautySalon_Script-Regular.otf') format("OpenType");
} */

#root {
  background-color: var(--background-main);
}

html {
  background-color: var(--background-main);
  color: var(--text-color);
  font-family: "Montserrat", sans-serif;
  text-align: center;
  scroll-behavior: smooth;
}

body {
  background-color: var(--background-main);
  color: var(--text-color);
  max-width: 2000px;
  font-family: "Montserrat", sans-serif;
}

svg:hover {
  cursor: pointer;
  
}

#nabber-icons a svg:hover {
  transform: scale(1.05);
  fill: var(--primary-color);
}

#outline-primary:hover {
  background-color: var(--primary-color);
}

/* #outline-secondary-tag {
  background-color: #6c757d;
} */

#outline-info-tag {
  background-color: #17a2b8;
}

.top-bumper {
  margin-top: 8vh;
}

.error {
  color: red;
  font-weight: bolder;
  font-size: medium;
  /* text-align: center; */
  text-shadow: 0 0 0.25em brown;
}


/* MARK: Homepage styles */

#main {
  background: var(--background-main);
}

/* #main .title {
  color: var(--primary-color);
  font-size: 6em;
} */

#main .subtitle {
  color: var(--text-color);
  font-size: 3.5em;
}

.ts-brand {
  color: var(--white-text-color);
  font-size: smaller;
  letter-spacing: 0.3em;
  margin: 2px;
  font-weight: bold;
}

/* MARK: registration and sign up forms */

#ignore-styles:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

input:-webkit-autofill {
  box-shadow: 0px 0px 7px 2px var(--primary-color),
    inset 0px 0px 0px 20px var(--background-main);
  -webkit-box-shadow: 0 0 0 50px var(--background-main) inset;
  -webkit-text-fill-color: var(--text-color);
}
input:-webkit-autofill:focus {
  box-shadow: 0px 0px 7px 2px var(--primary-color),
    inset 0px 0px 0px 20px var(--background-main
);
  -webkit-box-shadow: 0 0 0 50px var(--background-main)
    inset;
  -webkit-text-fill-color: var(--text-color);
}

.reg-div {
  background-color: var(--background-main);
  /* display: flex; */
  /* justify-content: center; */
}

#register-form,
#message-input {
  background-color: var(--background-main);
  color: var(--text-color);
}

#register-form {
  padding: 4%;
  max-width: 600px;
}

#register-form.fullsize,
.fullsize {
  width: 100%;
  max-width: 2000px;
  padding: 1.5%;
}

#custom-post-input {
  border: none;
}

#register-form input,
#message-input {
  background: var(--background-main);
  color: var(--text-color);
}

#register-form select {
  background: var(--background-main);
  color: var(--text-color);
}

#register-form input:focus,
#message-input input:focus {
  color: var(--text-color);
  box-shadow: 0px 0px 7px 2px var(--primary-color),
    inset 0px 0px 0px 20px var(--background-main
);
  -webkit-box-shadow: 0px 0px 7px 2px var(--primary-color),
    inset 0px 0px 7px 20px var(--background-main
);
  -moz-box-shadow: 0px 0px 7px 2px var(--primary-color),
    inset 0px 0px 7px 20px var(--background-main
);
}
#register-form select:focus {
  color: var(--text-color);
  box-shadow: 0px 0px 7px 2px var(--primary-color),
    inset 0px 0px 0px 20px var(--background-main
);
  -webkit-box-shadow: 0px 0px 7px 2px var(--primary-color),
    inset 0px 0px 7px 20px var(--background-main
);
  -moz-box-shadow: 0px 0px 7px 2px var(--primary-color),
    inset 0px 0px 7px 20px var(--background-main
);
}

#form-link {
  color: grey;
}

#form-link:hover {
  cursor: pointer;
  color: var(--text-color);
  text-decoration: underline;
}

/* //MARK: creator dashboard */
.dashboard {
  display: flex;
  justify-content: flex-start;
  background: var(--background-main);
  width: 100%;
}

.dash-menu {
  /* background: #f2f3f4; */
  /* padding: 15px; */
  width: 100%;
  padding: 5px;
  height: 85vh;
  position: fixed;
  top: 70px;
  /* border-right: solid 2px white; */
}

/* //MARK: TOOLTIP STYLES */

@media only screen and (min-width: 1025px) {

  [data-customTooltip] {
    cursor: pointer;
    position: relative;
    /* transition-delay: 1s; */
  }

  [data-customTooltip]::after {
    background-color: var(--post-background-color);
    color: var(--white-text-color);
    font-size: 12px;
    padding: 5px 8px;
    height: fit-content;
    width: fit-content;
    border-radius: 6px;
    position: absolute;
    z-index: 10;
    text-align: center;
    bottom: 0px;
    left: 50%;
    content: attr(data-customTooltip);
    /* transition-delay: 1s; */
    transform: translate(-50%, 110%) scale(0);
    transform-origin: top;
    transition: 0.14s;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.05);
  }

  [data-customTooltip]:hover:after {
    display: block;
    transition-delay: 0.35s;
    transform: translate(-50%, 110%) scale(1);
  }
}

/* //MARK: mobile drawer & dashboard */
.drawer-menu {
  background: var(--navy-blue);
  color: var(--white-text-color);
  height: 100%;
  width: 100%;
  padding: 5%;
}

.dash-stage {
  width: 82vw;
  background-color: var(--background-main);
  min-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  top: 70px;
  margin-bottom: 10vh;
}

/* //NOTE: check height and overflow scroll styling */
@media only screen and (max-width: 1025px) {
  .dash-stage {
    background-color: var(--background-main
);
    margin-left: 0%;
    padding: 0%;
    width: 100vw;
    height: 100vh;
    top: 0;
    overflow-y: scroll;
  }
}

.dash-stage div :not(.reactEasyCrop_Container .reactEasyCrop_Image) {
  background: var(--background-main);
  background: transparent;
  color: var(--text-color);
}

.rs-drawer-backdrop {
  opacity: 0.5 !important;
}

#menu-info {
  color: var(--text-color);
}

#menu-info > div {
  font-size: smaller;
}

.dash-menu .nav-link {
  width: 15vw;
  background: linear-gradient(
    to right,
    var(--primary-color) 0%,
    var(--primary-color) 100%
  );
  background-position: 100% 100% 0% 0%;
  background-repeat: repeat-y;
  background-size: 0px 4px;
  color: var(--text-color);
  padding: 1.5em;
  /* NOTE: text-shadow for selected page? */
  text-shadow: 0 0 0.5px #fff, 0 0 0.5px #bb86fc;
  transition: all 150ms linear;
  overflow: hidden;
  /* font-weight: bold; */
}

.dash-tab a.nav-link:focus, a.nav-link:active {
  font-weight: 600 !important;
  letter-spacing: 0.05rem;
}

@media only screen and (min-width: 600px) {
  .dash-tab a.nav-link:hover {
    background-size: 5px 10px;
    transform: scale(1.03);
    cursor: pointer;
    overflow: hidden;
  }
}

.drawer-menu .nav-link {
  size: 2.25em;
  color: var(--white-text-color);
  /* padding: 10px; */
}

#mobile-drawer-last-link {
  position: absolute;
  bottom: 2%;
}

.menu-span-active {
  color: var(--primary-color);
  margin-left: 6px;
  /* font-weight: 800; */
}

#footer-tabs {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin-bottom: 0.5em; */
  /* background: var(--background-main); */
}

#footer-tabs > div {
  width: 25%;
  display: flex;
  padding: 0.5em;
  justify-content: space-around;
  /* background: var(--background-main); */
}
/* 
#footer-tabs > div:last-child {
  flex-direction: column;
  align-content: center;
} */

#foot-tab {
  color: var(--text-color);
}

.main-image {
  width: 100vw;
  max-width: 100%;
}

.main-subtitle {
  width: 80%;
  margin: 7%;
}

.icon-image {
  margin-left: 0px;
  max-width: 45px;
  padding: 0%;
}

#brand {
  padding: 0.25%;
}

.menu-span {
  margin-left: 6px;
}

/* //MARK: content page and cards */
.content-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#card {
  width: 230px;
  height: 280px;
  border: 1px solid white;
  /* align-self: center; */
  color: var(--text-color);
}

#thumbnail-card {
  width: 100px;
  height: 100px;
  border: 1px solid white;
  /* margin: 4%; */
}

.option-icon > img {
  width: 65%;
  margin: auto;
}

.option-icon > span {
  font-size: smaller;
}

#card > img {
  width: 175px;
  margin: auto;
}

.card-deck {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* margin: auto; */
  align-content: center;
  justify-content: flex-start;
  align-self: center;
}

.card-deck > * {
  margin: 1%;
}

@media only screen and (max-width: 600px) {
  .card-deck {
    padding-left: 5%;
  }
}

.card-text {
  margin: 3%;
}

.grid-icon {
  display: flex;
  justify-content: flex-end;
  padding: 0.5%;
  margin-right: 3.5%;
}

.option-icon {
  display: flex;
  justify-content: flex-end;
  padding: 2.25%;
  margin-top: 1%;
}

/* //MARK: carousel for profile page */

.slide-image {
  width: 100%;
  height: 30vh;
  opacity: 0.7;
  object-fit: cover;
  overflow: hidden;
}

#slideshow {
  width: 100%;
  position: relative;
}

/* //MARK: DEFAULT BANNER OPTIONS WITH GRADIENT */

.default-banner-thumbnail {
  width: 125px !important;
  height: 125px !important;
  margin: 1%;
}

.default-banner-thumbnail:hover {
  cursor: pointer;
}

.default-banner {
  width: 100%;
  height: 30vh;
}

#banner-selection-box {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  min-height: 0;
  min-width: 0;
  height: 100%;
  width: auto;
  flex-direction: row;
}

.banner-selection-wrapper {
  height: 175px;
  position: relative;
  min-width: 100%;
}

.default-banner-thumbnail:focus {
  box-shadow: 3px 3px 10px 8px white;
}

#banner-navyred {
  background: #c31432; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #240b36,
    #c31432
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #240b36,
    #c31432
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-purplegreen {
  background: #59c173; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #5d26c1,
    #a17fe0,
    #59c173
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #5d26c1,
    #a17fe0,
    #59c173
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-paleyellowwhite {
  background: #ffefba; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffffff,
    #ffefba
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff,
    #ffefba
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-darkpurple {
  background: #610f40; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3c1053,
    #610f40
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3c1053,
    #610f40
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-limeblue {
  background: #00f260; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0575e6,
    #00f260
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #0575e6,
    #00f260
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-orange {
  background: #ff9966; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff5e62,
    #ff9966
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff5e62,
    #ff9966
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-lightblues {
  background: #74ebd5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #acb6e5,
    #74ebd5
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #acb6e5,
    #74ebd5
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-rosepink {
  background: #f4c4f3; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fc67fa,
    #f4c4f3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #fc67fa,
    #f4c4f3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-greys {
  background: #bdc3c7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2c3e50,
    #bdc3c7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2c3e50,
    #bdc3c7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-lushgreen {
  background: #56ab2f; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #a8e063,
    #56ab2f
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #a8e063,
    #56ab2f
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-darkblue {
  background: #000428; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #004e92,
    #000428
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #004e92,
    #000428
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-whitepink {
  background: #eecda3; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ef629f,
    #eecda3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ef629f,
    #eecda3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-neongreen {
  background: #fdfc47; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #24fe41,
    #fdfc47
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #24fe41,
    #fdfc47
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-redwine {
  background: #e52d27; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #b31217,
    #e52d27
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #b31217,
    #e52d27
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-orangeyellow {
  background: #e65c00; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f9d423,
    #e65c00
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f9d423,
    #e65c00
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#banner-neonpink {
  background: #f857a6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff5858,
    #f857a6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff5858,
    #f857a6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* //MARK: profile-header */

.profile-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
}


.profile-icons {
  width: 50%;
}
@media only screen and (max-width: 750px) {
  .profile-icons {
    width: 100%;
  }
}

#inventory-tab {
  background-color: var(--primary-color-selected);
}

#inventory-container {
  border: 3px solid var(--primary-color-selected);
}

.profile-header-container > div {
  width: auto;
  height: auto;
}

.profile-stats {
  /* display: flex;
  justify-content: space-around; */
  /* width: 80%; */
  position: absolute;
  z-index: 3;
  top: 8%;
  right: 5%;
}

.profile-stats > span {
  margin-left: 0.5em;
  font-weight: 700;
}

#trans {
  background: transparent;
}

.profile-avatar {
  margin-right: 2%;
  align-self: center;
  
}

#user-avatar {
  border-radius: 50%;
  width: 6em;
}

#post-avatar {
  border-radius: 50%;
  width: 3.25em;
}

/* //NOTE: added responsive text using "vw" and media queries */
.profile-header {
  width: 100%;
  height: auto;
  /* font-size: min(max(1rem, 4vw), 5em); */
  --minFontSize: 30px;
  --maxFontSize: 75px;
  --scaler: 10vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 600;
}

/* .profile-header svg {
  width: 100%;
  height: auto;
  fill: var(--text-color);
}

.profile-header > text {
  font-size: 5em;
} */

.user-bio {
  margin-top: 2vh;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 1.25em;
}

.sub-deets > * {
  padding-right: 0.5%;
  padding-left: 0.5%;
}

.sub-deets {
  font-size: 1.25em;
  display: flex;
  padding: 4%;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .profile-header {
    width: 100%;
    /* font-size: 12vw; */
  }
  .user-bio {
    font-size: 1em;
    /* margin: 1%; */
  }
  .sub-deets {
    font-size: 1em;
  }
}

.display-name {
  width: 100%;
  text-align: center;
  /* margin-top: 1.5vh; */
  font-size: 1.25em;
}

.profile-button-box {
  display: flex;
  justify-content: center;
  padding: 0.5vh;
  /* margin-top: 2vh; */
  /* margin-bottom: 0.25vh; */
}

.profile-button-box > .btn {
  margin: 2%;
}

/* //MARK: profile post & pinned container & post */

#new-post-bubble {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: var(--primary-color-selected);
  top: 10%;
  right: 5%;
  color: var(--text-color) !important;
  font-size: 1.15em;
  z-index: 9;
}

.pin-container {
  display: flex;
  align-self: center;
  margin: auto;
  margin-top: 5%;
  width: 92%;
  min-height: 100px;
  border: 1px solid pink;
  position: relative;
}

.pin-icon {
  position: absolute;
  z-index: 2;
  right: 1%;
  top: 1%;
}

.feed-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 3%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 100px;
  /* border: 1px solid lime; */
  margin-bottom: 10vh;
}

.feed-container .paginate {
  font-weight: bold;
}

.feed-container .paginate:hover {
  cursor: pointer;
}

.post-link:hover {
  cursor: pointer;
}
/* .overview-pane {
  border: 2px solid #ffc600;
  height: 100px;
  width: 100px;

} */

/* JOHNTRAVOLTA: */
.slut-bucket {
  display: flex;
  flex-flow: row wrap;
}

.post {
  width: 100%;
  min-height: 100px;
  /* border: 1px solid red; */
  /* padding-top: 1%;
  padding-bottom: 1%; */
  /* padding: 1%; */
  max-height: auto;
  position: relative;
  /* border-radius: 2.25em 0.5em 0.5em 0.5em; */
}

#post-container {
  background-color: var(--post-background-color);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
}

.post-img-container {
  /* margin: 1%; */
  /* width: 97%; */
  position: relative;
  display: flex;
  justify-content: center;
  /* height: 100%; */
  height: auto;
  max-height: 68vh;
  overflow: hidden;
}

.post-img-container > video {
  height: auto !important;
  max-height: 60vh;
  width: 100% !important;
}

#watermark {
  position: absolute;
  /* top: 20;
  left: 40; */
  color: black;
  size: 3em;
  font-weight: bold;
  /* height: 5%; */
  background-color: transparent !important;
}

.muted-text {
  color: var(--muted-text-color);
  font-size: smaller;
}

.disclaimer {
  color: grey;
  font-size: xx-small;
  text-align: center;
}

/* .muted-text:hover {
  cursor: pointer;
} */

#muted-text,
.btn.focus,
.btn:focus {
  color: var(--muted-text-color) !important;
  font-size: smaller;
  box-shadow: none;
}

#post-img {
  /* max-height: 70vh; */
  /* min-height: 30%; */
  max-width: 100%;
  transition: all 0.2s linear;
}

@media only screen and (min-width: 600px) {
  img#post-img:hover {
    transform: scale(1.05);
  }
}

.shrink-to-fit > p {
  width: auto;
}

.feed-container > .post {
  margin-bottom: 1em;
}

.post-header {
  padding: 1.5%;
}

.post-user {
  font-weight: bold;
  font-size: medium;
}

.paused {
  transform: scale(1.5);
  background: rgb(218, 166, 32) !important;
}

#money-coin {
  width: 3.15em;
  height: auto;
  align-self: flex-end;
  display: block;
  overflow: hidden;
  transition: all 200ms infinite;
}

#money-coin:hover, #money-coin:focus {
  cursor: pointer;
  background: rgb(218, 166, 32);
  animation: spin 1.5s ease;
  animation-fill-mode: forwards;
}


@keyframes spin {
  0% {
    transform: scale(1) rotateZ(0deg);
  }
  100% {
    transform: scale(1.5) rotateZ(720deg);
  }
}

.scroll-in {
  animation: fade-in 0.5s linear;
  /* animation-play-state: paused; */
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  opacity: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#tip-modal-header {
  background-color: var(--background-main);
  color: white;
  text-align: center;
  padding: auto;
}

#tip-modal-header > button {
  color: white;
}

#tip-modal-body {
  background-color: var(--post-background-color);
  color: var(--text-color);
  width: auto;
}



.post-text {
  min-height: 1em;
  max-width: 500px;
  width: auto;
  margin: auto;
  padding: 0.75%;
  text-align: center;
}

.post-icon-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  /* padding-right: 10%; */
  /* padding-top: 2%; */
  padding: 0.25%;
}

.fire-icon > div {
  margin: 0.25%;
}

.lock-box {
  width: 100%;
  min-height: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-timestamp {
  font-size: smaller;
  color: var(--muted-text-color);
}

/* //MARK: COMMENT FORM ON POST */

#post-comment-form {
  position: sticky;
  /* right: 0; */
  /* left: 0; */
  top: 0;
  /* width: 100% !important; */
  /* margin: 0 -10px 0 -10px; */
}

#post-comment-input {
  background-color: var(--background-main);
  border: none;
  width: 100%;
  /* height: 1.5em; */
}

#post-comment-input:focus {
  color: var(--text-color);
  box-shadow: none;
}

/* //MARK: IMAGE MODAL */
.modal-pic {
  width: 100%;
  max-width: 95vw;
  max-height: 90vh;
  position: relative;
  /* align-self: center; */

  object-fit: scale-down;
}

.pop-modal .modal-content {
  background-color: transparent !important;
  border: none;
}

@media only screen and (max-width: 550px) {
  .modal-pic {
    margin-top: 30% !important;
  }
  .close-icon {
    position: absolute;
    z-index: 2;
    right: 3%;
    top: 3%;
  }
}

.close-container {
  width: 95vw;
  max-height: 90vh;
  position: relative;
  top: 3%;
  z-index: 2;
  align-self: center;
}

.close-icon {
  position: absolute;
  z-index: 2;
  right: -2%;
  /* top: 8%;  */
}

.close-icon:hover {
  cursor: pointer;
}

.modal-container {
  position: relative;
  /* z-index: 2; */
  height: 100%;
  width: 100vw;
  align-self: center;
}

.circle {
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 10px;
  /* border: 1px solid greenyellow;
    background-color: greenyellow !important; */
  width: 10px;
  height: 10px;
  position: absolute;
}

.online-now {
  border: 1px solid greenyellow;
  background-color: greenyellow !important;
  right: 0%;
  z-index: 2;
}

.new-notifications {
  border: 1px solid red;
  background-color: red !important;
  right: 25%;
  top: 4%;
}

.parent-container {
  position: relative;
}

/* .parent-container > div {
  background-color: transparent;
} */

/* //MARK: Inbox page and messaging components */

.inbox-page {
  width: 100%;
}

.header {
  width: 100%;
  height: 4.5vh;
  display: flex;
  position: fixed;
  /* transform: translateY(-50%); */
  top: 0;
  /* margin-bottom: 4.5vh; */
  /* justify-content: center;
  align-items: center; */
  font-weight: bold;
}

.desktop-header .header {
  width: 100%;
  height: 4.5vh;
  display: flex;
  position: fixed;
  transform: translateY(-6vh);
  font-weight: bold;
  cursor: pointer;
}

.desktop-header.left {
  margin-top: 1.5%;
}

.center {
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.left {
  align-items: center;
  padding-left: 2.5%;
  font-weight: bold;
  cursor: pointer;
}

.convo-preview {
  /* border: 1px solid greenyellow; */
  padding: 1.5%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.convo-preview-bulk {
  margin-left: 3%;
  width: 80%;
}

.convo-preview-time {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  font-size: 0.75em;
}

.convo-preview-user {
  font-weight: bold;
  font-size: 1.15em;
}

.convo-preview-snippet {
  font-size: 0.9em;
}

#convo-container {
  /* background-image: url('../images/convo-bkgrd.png'); */
  background: var(--background-main);
  /* background: linear-gradient(0deg, rgba(166,111,233,0.5144432773109244) 0%, rgba(71,64,64,0.623686974789916) 29%); */
}

.convo-container {
  /* margin-top: 4.5vh; */
  padding: 2.5%;
  height: 75vh;
  overflow-y: scroll;
  /* background-color: lime; */
  /* overscroll-behavior-y: contain; */
  /* scroll-snap-type: y proximity; */
  margin-left: 0%;
  margin-right: 0%;
  /* display: inline-block; */
  /* position: relative; */
  width: auto;
}
@media only screen and (max-width: 550px) {
  .convo-container {
    margin-top: 4.5vh;
    height: 82vh;
    /* width: 90%; */
  }
  #accordion {
    margin-top: 4.5vh;
  }
}

/* //MARK: NEW MESSAGE INPUT ON CONVERSATION */
.convo-message-form {
  width: 100%;
}

#message-form {
  /* display: flex; */

  width: 100%;
  padding: 1%;
  position: absolute;
  height: auto;
  bottom: 65px;
  border-top: 2px solid var(--post-background-color);
}

#message-input {
  /* display: table-cell; */
  width: 85%;
  height: auto;
  resize: none;
  border: none;
  margin: 2%;
  /* border: var(--background-main); */
}

#message-input:focus {
  outline: none;
  border: none;
}

#message-btn {
  margin: 1%;
  font-size: 2.5em;
  color: var(--post-background-color);
}

#message-btn:hover {
  cursor: pointer;
}

.green-go {
  color: greenyellow !important;
}

#media-message-form {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: var(--primary-color-selected);
  top: -60%;
  right: 5%;
  color: var(--text-color) !important;
  font-size: 1.15em;
  /* -webkit-user-drag: element; */
}

/* #media-message-form > div {
  color: var(--muted-text-color);
} */
/* #message-btn-box {
  display: block;
  border-radius: 50%;
  /* width: 7%; */


.message-blurb {
  /* border: 2px solid pink; */
  /* width: 100%; */
  /* display: flex; */
  margin: 1%;
  padding: 0.50%;
  position: relative;
  display: inline-block;

}

.message-blurb-bulk {

  display: flex;

}

#message-bubble {
  font-size: 0.9em;
  color: black;
  background-color: rgb(212, 235, 248);
  padding: 1.25%;
  padding-left: 1.75%;
  padding-right: 1.75%;
  padding-top: 1.75%;
  /* text-align: center; */
  min-width: 15%;
}

#message-bubble > p {
  color: black;
  white-space: pre-wrap;
  /* min-width: 20%; */
  /* padding: 1%; */
}

.message-blurb-time {
  font-size: 0.75em;
}

.message-blurb-time > p {
  text-align: center;
  padding: 3%;
}

/* //MARK: Settings page */

/* .settings-page {
  padding-bottom: 2%;
  margin-bottom: 3%;
} */
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.edit-avatar-container {
  display: flex;
  flex-direction: column;
}

.avatar-preview {
  display: flex;
  flex-direction: row;
}

.card-header {
  font-weight: bold;
  padding-bottom: 2%;
  border: 1px solid var(--primary-color);
}

.card-header:hover {
  cursor: pointer;
}

.settings-header {
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-self: center;
  height: 4.5vh;
  font-size: 1.75em;
}

/* //MARK: Spinner and Loading  */

.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  /* margin: 10%; */
  align-items: center;
  justify-content: center;
  /* position: absolute;
  z-index: 5; */
  /* background-color: #ECF0F1; */
  /* object-fit: fill; */
}

/* .spinner {
  /* height: 70%; */
  /* width: 60vw;
  margin: auto;
} */ 

.folding-heart {
  /* margin-top: 25%; */
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  background-color: #904ce3 !important;
}
.folding-heart,
.folding-heart::before,
.folding-heart::after {
  display: block;
  width: 50px;
  height: 50px;
  background-color: #904ce3;
  transform: rotateZ(45deg);
}
.folding-heart::before,
.folding-heart::after {
  content: '';
  position: absolute;
  border-radius: 50%;
}
.folding-heart::before {
  transform: translateX(-50%);
  -webkit-animation: animX 3s infinite 0.75s;
          animation: animX 3s infinite 0.75s;
}
.folding-heart::after {
  transform: translateY(-50%);
  -webkit-animation: animY 3s infinite;
          animation: animY 3s infinite;
}
@-webkit-keyframes animX {
  0%, 25% {
    transform: translateX(-50%);
  }
  50%, 75% {
    transform: translateX(50%);
  }
}
@keyframes animX {
  0%, 25% {
    transform: translateX(-50%);
  }
  50%, 75% {
    transform: translateX(50%);
  }
}
@-webkit-keyframes animY {
  0%, 25% {
    transform: translateY(-50%);
  }
  50%, 75% {
    transform: translateY(50%);
  }
}
@keyframes animY {
  0%, 25% {
    transform: translateY(-50%);
  }
  50%, 75% {
    transform: translateY(50%);
  }
}

/* #heart-path {
  animation: loading-spin 2s infinite;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@keyframes loading-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

/* //MARK: CREATE NEW POST FORM AND COMPONENTS */

#upload-preview {
  padding: 1%;
}

#image-preview {
  max-height: 35vh;
}

#image-preview:hover {
  cursor: pointer;
}

.icon-options {
  justify-content: space-evenly;
  padding: 1.5%;
  border-top: solid 1px var(--text-color);
}

#video-preview {
  max-width: 90%;
}

#schedule-post, #calendar-tile {
  max-width: 300px;
  background-color: var(--background-main);
}

/* @media only screen and (max-width: 550px) {
  #calendar-tile {
    width: 90%;
  }
} */

#schedule-post > div,
ul,
li {
  background: var(--background-main) !important;
}

#schedule-post > span:blur {
  color: var(--background-main);
}

#schedule-post .rs-calendar,
.rs-calendar-table {
  background: var(--background-main);
}

#schedule-post .rs-calendar-time-dropdown-column-title {
  background: var(--background-main) !important;
}

#schedule-buttons {
  margin: auto;
  max-width: 200px;
  justify-content: space-evenly;
}

#schedule-post
  .rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  background: var(--primary-color) !important;
}

#schedule-post
  .rs-calendar-table-cell-is-today
  .rs-calendar-table-cell-content {
  border-color: var(--primary-color) !important;
}

#schedule-post
  .rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell-active,
.rs-calendar-time-dropdown-cell:hover {
  background-color: var(--primary-color) !important;
}

input::-webkit-datetime-edit-fields-wrapper {
  background: green;
}

/* //MARK: USER TAGS - PILL BUTTONS */

.user-tags {
  margin: 0.25%;
}

#tag-row {
  margin-top: 1.5%;
}

/* //MARK: BEATING & GLOWING HEART ICON */

#heart {
  fill: var(--primary-color);
  position: relative;
  width: 3em;
  cursor: pointer;
  -webkit-transition: all .05s ease-in-out;
  transition: all .05s ease-in-out;
}

/* //FIX: needs media query to ignore mobile */

@media only screen and (min-width: 1025px) {

  #heart:hover {
    animation: pulse 1s ease-in-out infinite; 
  }
  
  #heart:hover path {
    transition: 0.2s;
    d: path("M16.5 3C19.538 3 22 5.5 22 9c0 7-7.5 11-10 12.5C9.5 20 2 16 2 9c0-3.5 2.5-6 5.5-6C9.36 3 11 4 12 5c1-1 2.64-2 4.5-2z")
  }

}

#heart-filled {
  fill: var(--primary-color);
  position: relative;
  width: 3em;
  cursor: pointer;
  filter: drop-shadow(0 0 1.2em #d3b0ff);
  /* d: path("M16.5 3C19.538 3 22 5.5 22 9c0 7-7.5 11-10 12.5C9.5 20 2 16 2 9c0-3.5 2.5-6 5.5-6C9.36 3 11 4 12 5c1-1 2.64-2 4.5-2z") */
}

.glow {
  filter: drop-shadow(0 0 1.2em var(--text-color));
}

@keyframes pulse {
  0% { 
    transform: scale(1); 
    filter: drop-shadow(0 0 1.2em #d3b0ff);
  }
  70% {
    transform: scale(0.9); 
    filter: drop-shadow(0 0 0.5em #d3b0ff);
  }
  100% { 
    transform: scale(1); 
    filter: drop-shadow(0 0 1.2em #d3b0ff);
  }
}
